import { useCallback, useEffect, useState } from 'react';

import { ISellerOrder } from 'models/order';
import { ISellerOrderHistory } from 'models/order/history';
import { IVolume } from 'models/order/volume';
import { shipmentTypesReverse } from 'utils/order/constants';

import { Director, TimelineBuilder } from '../builder';
import { Step } from '../builder/Step';

function useStepsTimeline(
  packageVolume: IVolume,
  orderHistory?: ISellerOrderHistory[],
  orderIsCanceled = false,
  newDeliveryDate?: Date,
  urlInvoice?: string,
  resendSellerOrder?: ISellerOrder | null
) {
  const [stepsTimeline, setStepsTimeline] = useState<Step[]>([]);

  const { packages, status } = packageVolume;

  const getDirectorAndBuilder = useCallback(() => {
    const director = new Director();
    const builder = new TimelineBuilder();
    director.setBuilder(builder);

    return { director, builder };
  }, []);

  useEffect(() => {
    const { builder, director } = getDirectorAndBuilder();

    const infosPackage = {
      newDeliveryDate,
      orderIsCanceled,
      packages,
      urlInvoice,
      volumeStatus: status,
    };

    const isReversePackage = packages?.some(({ shipmentType }) =>
      shipmentTypesReverse.includes(shipmentType)
    );

    const buildTimeline =
      !stepsTimeline.length || (Boolean(stepsTimeline.length) && Boolean(newDeliveryDate));

    if (buildTimeline && orderHistory) {
      director.buildTimeline();

      if (resendSellerOrder) {
        director.buildResendSellerOrder(resendSellerOrder);
      }

      if (isReversePackage) {
        const reversaIsReadyToShip = packages
          ?.filter(({ shipmentType }) => shipmentTypesReverse.includes(shipmentType))
          .every(({ status: statusPkg }) => statusPkg === 'ready_to_ship');

        const isOnlyReverseSteps =
          reversaIsReadyToShip && (orderIsCanceled || status === 'canceled');

        director.buildCurrentStatusWithReverse(infosPackage, orderHistory, isOnlyReverseSteps);
      } else {
        director.buildCurrentStatus(infosPackage, orderHistory);
      }

      const steps = builder.getStepsTimeline();
      setStepsTimeline(steps);
    }
  }, [
    getDirectorAndBuilder,
    newDeliveryDate,
    orderHistory,
    orderIsCanceled,
    resendSellerOrder,
    packages,
    status,
    stepsTimeline.length,
    urlInvoice,
  ]);

  return { stepsTimeline };
}

export default useStepsTimeline;
