import { useQuery } from '@tanstack/react-query';

import { IPackage } from 'models/order/package';
import { request } from 'utils/request';

const usePackageData = (packageVolumeId?: string, isVolumeId = false) => {
  const fetchPackage = async (id?: string): Promise<IPackage> => {
    const fetchUrl = isVolumeId ? `packages/volumes/${id}` : `packages/${id}`;

    const response = await request.get(fetchUrl);

    return response.data;
  };

  const { data, isError, isLoading, isSuccess, refetch } = useQuery<IPackage, Error>(
    ['packageDataRequest', packageVolumeId],
    () => fetchPackage(packageVolumeId),
    {
      enabled: Boolean(packageVolumeId),
      refetchInterval: false,
      retry: false,
      cacheTime: 60 * 60 * 3,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    packageData: data,
    isLoading,
    fetchPackage: refetch,
    isSuccess,
    isError,
  };
};

export default usePackageData;
