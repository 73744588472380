import { ISellerOrder } from 'models/order';
import { ISellerOrderHistory } from 'models/order/history';
import { IPackage } from 'models/order/package';
import { StepStatus } from 'models/steps';
import { getFormattedDateBR } from 'utils/formatters';
import { shipmentTypesReverse } from 'utils/order/constants';

import { InfosPackage } from './models';
import { Step } from './Step';
import {
  stepsOrderHistoryDefault,
  stepsResendDefault,
  stepsReveseDefault,
  stepsVolumeStatusDefault,
} from './utils/steps';
import { NewDeliveryDate } from '../components/NewDeliveryDate';

export class Timeline {
  stepsOrderHistory: Step[] = [];

  stepsVolumeStatus: Step[] = [];

  stepsReverse?: Step[];

  stepsResend?: Step[];

  volumeStatus!: string;

  packages: IPackage[] = [];

  newDeliveryDate?: Date;

  urlInvoice?: string;

  resendSellerOrder?: ISellerOrder | null;

  orderIsCanceled?: boolean;

  setResendSellerOrder(resendSellerOrder: ISellerOrder | null) {
    this.resendSellerOrder = resendSellerOrder;
  }

  setStepsVolumeStatus() {
    const stepsVolumeStatus = stepsVolumeStatusDefault.map(
      (step) => new Step(step.key, step.icon, step.name, step.status)
    );

    this.stepsVolumeStatus = stepsVolumeStatus;
  }

  setStepsOrderHistory() {
    const stepsOrderHistory = stepsOrderHistoryDefault.map(
      (step) => new Step(step.key, step.icon, step.name, step.status)
    );

    this.stepsOrderHistory = stepsOrderHistory;
  }

  setInfosPackage(infosPackage: InfosPackage) {
    const {
      newDeliveryDate,
      orderIsCanceled = false,
      packages = [],
      urlInvoice,
      volumeStatus,
    } = infosPackage;

    this.volumeStatus = volumeStatus;
    this.packages = packages;
    this.urlInvoice = urlInvoice;
    this.orderIsCanceled = orderIsCanceled;
    this.newDeliveryDate = newDeliveryDate;
  }

  setStepsReverse() {
    const packagesReverse = this.getPackagesReverse();

    if (!packagesReverse.length) return;

    const stepsReverse = stepsReveseDefault.map(
      (step) =>
        new Step(
          step.key,
          step.icon,
          step.name,
          step.status,
          undefined,
          StepStatus.inactive,
          undefined,
          true
        )
    );

    this.stepsReverse = stepsReverse;
  }

  setStepsResend() {
    const stepsResend = stepsResendDefault.map(
      (step) =>
        new Step(
          step.key,
          step.icon,
          step.name,
          step.status,
          undefined,
          StepStatus.inactive,
          undefined
        )
    );

    this.stepsResend = stepsResend;
  }

  getPackagesReverse(): IPackage[] {
    const packagesIsReverse = this.packages?.filter(({ shipmentType }) =>
      shipmentTypesReverse.includes(shipmentType)
    );

    packagesIsReverse?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateA.getTime() - dateB.getTime();
    });
    return packagesIsReverse;
  }

  getPackagesResend(): IPackage[] {
    const packagesIsResend = this.packages?.filter(({ shipmentType }) => shipmentType === 'resend');

    return packagesIsResend;
  }

  getStepsOrderHistory(): Step[] {
    return this.stepsOrderHistory;
  }

  getStepsResend(): Step[] {
    return this?.stepsResend || [];
  }

  getStepsVolumeStatus(): Step[] {
    return this.stepsVolumeStatus;
  }

  getStepsReverse(): Step[] {
    return this?.stepsReverse || [];
  }

  getAllSteps(): Step[] {
    const stepsOrderHistory = this.getStepsOrderHistory();
    const stepsVolumeStatus = this.getStepsVolumeStatus();
    const stepsReverse = this.getStepsReverse() || [];

    const allSteps = [...stepsOrderHistory, ...stepsVolumeStatus, ...stepsReverse];

    return allSteps;
  }

  checkAllStepsIsInactive(): boolean {
    const allSteps = this.getAllSteps();

    const allStepsIsInactive = allSteps.every(
      ({ currentStatus }) => currentStatus === StepStatus.inactive
    );
    return allStepsIsInactive;
  }

  getStatusStepsVolumeIsCompleted(): StepStatus[] {
    const stepsVolumeStatus = this.getStepsVolumeStatus();

    const stepIsCompleted = stepsVolumeStatus.filter(
      ({ currentStatus }) => currentStatus !== StepStatus.inactive
    );

    const statusStepsVolumeIsCompleted = stepIsCompleted.map(({ currentStatus }) => currentStatus);

    return statusStepsVolumeIsCompleted;
  }

  getDescriptionDeliveryPackage(status: string, keyStep?: string): string | undefined {
    if (!keyStep || keyStep !== 'awaitingDelivery') return undefined;
    if (status !== 'delivered') return undefined;

    const normalShipmentPackage = this.packages?.find(
      ({ shipmentType }) => shipmentType === 'normal'
    );

    if (!normalShipmentPackage) return undefined;

    const dateToDescription = new Date(normalShipmentPackage?.lastTrackingDate);
    const descriptionDate = getFormattedDateBR(dateToDescription);

    return descriptionDate;
  }

  getComplementStep(stepKey?: string): JSX.Element | undefined {
    const containsNewDeliveryDateComplement =
      this.volumeStatus !== 'delivered' &&
      this.volumeStatus !== 'canceled' &&
      !this.orderIsCanceled;

    const keyStepIsAwaitingDelivery = stepKey === 'awaitingDelivery';

    if (this.newDeliveryDate && containsNewDeliveryDateComplement && keyStepIsAwaitingDelivery) {
      return <NewDeliveryDate newDate={this.newDeliveryDate} />;
    }

    return undefined;
  }

  removeStepsOrderHistory(): void {
    this.stepsOrderHistory = [];
  }

  removeStepsVolumeStatus(): void {
    this.stepsVolumeStatus = [];
  }

  checkNextStepVolumeStatusIsDone(): boolean {
    return this.stepsVolumeStatus.some(
      ({ currentStatus }) => currentStatus !== StepStatus.inactive
    );
  }

  checkNextStepReverseIsDone(): boolean {
    return (
      this.stepsReverse?.some(({ currentStatus }) => currentStatus !== StepStatus.inactive) || false
    );
  }

  checkNextStepResendIsDone(): boolean {
    return (
      this.stepsResend?.some(({ currentStatus }) => currentStatus !== StepStatus.inactive) || false
    );
  }

  checkIsNewDeliveryDateStep(stepKey?: string): boolean {
    const isNewDeliveyDateStep = Boolean(
      stepKey === 'packageInTransit' && this.newDeliveryDate && this.volumeStatus !== 'delivered'
    );

    return isNewDeliveyDateStep;
  }

  getCurrentStatusStepOrderHistory(previousStep?: Step): StepStatus | null {
    const statusStepsVolumeCompleted = this.getStatusStepsVolumeIsCompleted();
    const timelineContainsNewDeliveryDate = Boolean(this.newDeliveryDate);

    const containsErrorCurrentStepsVolume = statusStepsVolumeCompleted?.find(
      (mandatoryStatus) => mandatoryStatus === StepStatus.error
    );

    if (containsErrorCurrentStepsVolume && !timelineContainsNewDeliveryDate) {
      return StepStatus.error;
    }

    const containsWarningCurrentStepsVolume = statusStepsVolumeCompleted?.find(
      (mandatoryStatus) => mandatoryStatus === StepStatus.warning
    );

    if (containsWarningCurrentStepsVolume && !timelineContainsNewDeliveryDate) {
      return StepStatus.warning;
    }

    const currentStepIsDone =
      previousStep &&
      (previousStep?.currentStatus === StepStatus.done ||
        previousStep?.currentStatus === StepStatus.active);

    const isDoneStatus =
      statusStepsVolumeCompleted?.find(
        (mandatoryStatus) =>
          mandatoryStatus === StepStatus.done || mandatoryStatus === StepStatus.active
      ) || currentStepIsDone;

    if (isDoneStatus) {
      return StepStatus.done;
    }
    return null;
  }

  setCurrentStatusStepsOrderHistory(orderHistory: ISellerOrderHistory[]) {
    const stepsOrderHistory = [...this.getStepsOrderHistory()].reverse();
    const orderHistorySortCreatedAt = orderHistory.sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    const packageIsResend = this.packages?.every(({ shipmentType }) => shipmentType === 'resend');
    const isResendPackage = this.packages.length > 1 && packageIsResend;

    stepsOrderHistory.forEach((step) => {
      orderHistorySortCreatedAt.forEach(({ createdAt, status }, currentIndex) => {
        const dateToDescription = new Date(createdAt);
        const descriptionDate = getFormattedDateBR(dateToDescription);

        if (step?.currentStatus !== StepStatus.inactive) {
          const statusInThisStep = step.status.includes(status);
          if (statusInThisStep) {
            step.setCurrentDescription(step?.currentStatus, descriptionDate);
          }
          return;
        }
        const perviousStep = stepsOrderHistory[currentIndex - 1];

        const currentStatus = this.getCurrentStatusStepOrderHistory(perviousStep) || status;
        const stepStatus = step.setCurrentStatusStep(currentStatus);
        step.setCurrentDescription(stepStatus, descriptionDate);
        step.setCurrentName(this.volumeStatus, { isExchange: isResendPackage });
        step.setCurrentLink(stepStatus, { href: this.urlInvoice });
      });
    });
  }

  setCurrentStatusStepsFromVolume() {
    const stepsVolumeStatus = [...this.getStepsVolumeStatus()].reverse();

    stepsVolumeStatus.forEach((step) => {
      const { key } = step;
      const complementStep = this.getComplementStep(key);
      const nextStepIsDone = this.checkNextStepVolumeStatusIsDone();
      const isNewDeliveryDate = this.checkIsNewDeliveryDateStep(key);
      const status = this.orderIsCanceled ? 'canceled' : this.volumeStatus;
      const packageIsResend = this.packages?.some(({ shipmentType }) => shipmentType === 'resend');
      const isResend =
        (Boolean(this.packages.length) && packageIsResend) || Boolean(this.resendSellerOrder);
      const descriptionDate = this.getDescriptionDeliveryPackage(status, step.key);

      step.setCurrentLink(this.volumeStatus, {
        isResend,
        isCanceled: this.orderIsCanceled,
      });
      step.setCurrentName(this.volumeStatus, { isCanceled: this.orderIsCanceled });
      step.setCurrentIcon(status);
      step.setCurrentStatusStep(status, nextStepIsDone, isNewDeliveryDate);
      step.setComplement(complementStep);
      step.setCurrentDescription(status, descriptionDate);
    });
  }

  setCurrentStatusStepsReverse() {
    const stepsReverse = [...this.getStepsReverse()].reverse();

    const packagesIsReverse = this.getPackagesReverse();

    if (!packagesIsReverse.length) return;

    stepsReverse.forEach((step) => {
      packagesIsReverse.forEach(({ createdAt, status, updatedAt }) => {
        const nextStepIsDone = this.checkNextStepReverseIsDone();

        const dateToDescription =
          step.key === 'requestedReverse' ? new Date(createdAt) : new Date(updatedAt);
        const currentStatus = step.setCurrentStatusStep(status, nextStepIsDone);
        const descriptionDate = getFormattedDateBR(dateToDescription);
        step.setCurrentDescription(currentStatus, descriptionDate);
        step.setCurrentLink(status, { isReverse: true });
      });
    });
  }

  setCurrentStatusStepsResend() {
    const stepsResend = [...this.getStepsResend()].reverse();
    const packagesIsResend = this.getPackagesResend();

    stepsResend.forEach((step) => {
      packagesIsResend.forEach(({ createdAt, status, updatedAt }) => {
        const nextStepIsDone = this.checkNextStepResendIsDone();

        const reversePackage = this.packages?.filter((packageItem) =>
          shipmentTypesReverse.includes(packageItem.shipmentType)
        );

        const isResend = packagesIsResend && !reversePackage.length && this.packages.length > 1;

        const dateToDescription =
          step.key === 'orderConfirmed' ? new Date(createdAt) : new Date(updatedAt);
        const currentStatus = step.setCurrentStatusStep(status, nextStepIsDone);
        const descriptionDate = getFormattedDateBR(dateToDescription);
        step.setCurrentName(this.volumeStatus, { isResend });
        step.setCurrentDescription(currentStatus, descriptionDate);
        step.setCurrentLink(status, {});
      });
    });
  }
}
