import useNewDeliveryDate from 'hooks/order/useNewDeliveryDate';
import { ISellerOrder } from 'models/order';
import { addInitialHourToDate } from 'utils/formatters';
import { getNameAndVariationStatusOrder } from 'utils/order';
import { shipmentTypesReverse } from 'utils/order/constants';

import { HeaderOrderDetails } from './HeaderOrderDetails';
import { Container } from './OrderDetails.styles';
import { OrderItems } from './OrderItems';
import { Packages } from './Packages';
import { Resume } from './Resume';
import { suspensionsIsCanceled } from './utils/constants';

interface OrderDetailsProps {
  order: ISellerOrder;
}

export function OrderDetails({ order }: OrderDetailsProps) {
  const {
    cancellation,
    channelOrderCode,
    countItems,
    orderItems,
    resendSellerOrder,
    sellerOrderHistory,
    status,
    suspensionReason,
    volumes,
  } = order;

  const dateNow = new Date();
  const isCanceledBySuspensionReason =
    suspensionReason && suspensionsIsCanceled.includes(suspensionReason) && status !== 'delivered';

  const isCanceled = isCanceledBySuspensionReason || Boolean(cancellation) || status === 'canceled';

  const containsReverseIsReadyToShipped = volumes?.some(({ packages }) =>
    packages?.some(
      (packageItem) =>
        packageItem?.status === 'ready_to_ship' &&
        shipmentTypesReverse.includes(packageItem.shipmentType)
    )
  );

  const orderInfoStatus = getNameAndVariationStatusOrder(isCanceled ? 'canceled' : status);

  const estimatedDeliveryDate = order?.estimatedDeliveryDate
    ? new Date(addInitialHourToDate(order?.estimatedDeliveryDate))
    : undefined;

  const probalyOrderIsLate =
    estimatedDeliveryDate && dateNow.getTime() >= estimatedDeliveryDate.getTime();

  const enabledGetNewDeliveryDate =
    !isCanceled && !suspensionReason && channelOrderCode && probalyOrderIsLate;

  const { newDeliveryDate } = useNewDeliveryDate(
    enabledGetNewDeliveryDate ? channelOrderCode : undefined
  );

  const realDeliveryDate = newDeliveryDate
    ? addInitialHourToDate(newDeliveryDate?.newDeliveryDate)
    : undefined;

  const deliveryDate = realDeliveryDate || estimatedDeliveryDate;

  const lateNewDeliveryDate = newDeliveryDate && newDeliveryDate?.newDeliveryDateIsPast;
  const countVolumes = volumes?.length;

  return (
    <Container>
      <HeaderOrderDetails
        name={orderInfoStatus.name}
        hideBadge={containsReverseIsReadyToShipped}
        variantionBadge={orderInfoStatus.variation}
        lateNewDeliveryDate={lateNewDeliveryDate}
      />
      <Resume
        {...order}
        code={order.channelOrderCode}
        orderDate={new Date(order.createdAt)}
        estimatedDeliveryDate={deliveryDate}
        isCanceled={isCanceled}
        reverseIsReadyToShipped={containsReverseIsReadyToShipped}
      />

      {volumes && (
        <Packages
          countItems={countVolumes || countItems}
          isCanceled={isCanceled}
          invoiceUrl={order?.invoiceUrl}
          deliveryDate={realDeliveryDate}
          sellerOrderHistory={sellerOrderHistory}
          volumes={volumes}
          resendSellerOrder={resendSellerOrder}
        />
      )}

      {orderItems && <OrderItems orderItems={orderItems} />}
    </Container>
  );
}
