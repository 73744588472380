import { useTranslation } from 'react-i18next';

import { Badge, Caption } from '@olist/design-system';
import { BadgeVariation } from '@olist/design-system/dist/esm/types/src/components/Badge/Badge';
import { LightbulbOutline } from '@olist/design-system-icons';

import { KnowledgePillContainer, KnowledgePillContent } from './KnowledgePill.styles';

type KnowledgePillProps = {
  description: string;
  title: string;
  type: BadgeVariation;
};

export const KnowledgePill = ({ description, title, type }: KnowledgePillProps) => {
  const { t } = useTranslation();

  return (
    <KnowledgePillContainer>
      <Badge showIcon={false} variation={type}>
        {t(title)}
      </Badge>
      <KnowledgePillContent>
        <LightbulbOutline width={20} />
        <Caption>{t(description)}</Caption>
      </KnowledgePillContent>
    </KnowledgePillContainer>
  );
};
