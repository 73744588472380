import ImageNext from 'next/image';
import styled from 'styled-components';

const Image = styled(ImageNext)`
  object-fit: contain;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const WrapperProduct = styled.div`
  margin-left: ${({ theme }) => theme.olist.spacing.inline.small};
`;

const WrapperInfoProduct = styled.div`
  span {
    font-size: ${({ theme }) => theme.olist.fontSize['1xmini']};
    color: ${({ theme }) => theme.olist.color.neutral.strong};
  }

  > span:nth-child(2) {
    margin-left: ${({ theme }) => theme.olist.spacing.inline.large};
  }
`;

export { Image, Container, WrapperInfoProduct, WrapperProduct };
