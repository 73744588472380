const statusFilters = [
  {
    value: 'all',
    name: 'all orders',
  },
  {
    value: 'pending',
    name: 'in progress',
  },
  {
    value: 'delivered',
    name: 'finalized',
  },
];

const valuesPending = [
  'shipped',
  'pending',
  'created',
  'processing_volumes',
  'carrier_pending',
  'carrier_assigned',
  'generated',
  'approved',
  'invoiced',
  'payment_approved',
  'created',
  'ready_to_ship',
  'collected',
  'received',
  'processing',
];
const shipmentTypesReverse = ['reverse', 'reverse_ticket', 'reverse_hub'];

export { statusFilters, shipmentTypesReverse, valuesPending };
