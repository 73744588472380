import { returnToSenderStatus } from './constants';
import { DescriptionLink } from '../models';

type description = {
  [key: string]: DescriptionLink;
};

export const getMessageType = (
  status: string,
  isOrderCanceled = false,
  isReversePackage = false
): string => {
  const isReturnedToSender = returnToSenderStatus.includes(status);
  const isLost = status === 'lost';
  const isCanceledPackage = status === 'canceled' || isOrderCanceled;
  const isDeliveryAttempt = status === 'delivery_attempt';
  const isWaitingPickupReceiver = status === 'waiting_pickup_by_receiver';
  const isAddressNotFound = status === 'address_not_found';
  const isReverseDelivered = status === 'delivered' && isReversePackage;

  if (isReturnedToSender) return 'returnToSender';
  if (isLost) return 'lost';
  if (isCanceledPackage) return 'canceled';
  if (isDeliveryAttempt) return 'deliveryAttempt';
  if (isWaitingPickupReceiver) return 'waitingPickupReceiver';
  if (isAddressNotFound) return 'addressNotFound';

  if (isReverseDelivered) return 'reverseDelivered';

  return '';
};

export const getInfoLinkKnowlogeBills = (messageType?: string): DescriptionLink | undefined => {
  if (!messageType) return undefined;

  const descriptions: description = {
    returnToSender: {
      title: 'return',
      message: `Unfortunately, the logistics partner informed us that the delivery could not be carried out and the item has already been sent to the store. If we still haven't resolved what happened, contact us through the chat where the purchase was made and we will together understand what happened`,
      type: 'error',
    },
    lost: {
      title: 'canceled',
      message: `Our logistics partner has informed us that there has been an incident with your package. Don't worry, contact us through the chat where the purchase was made and we will together understand what happened`,
      type: 'error',
    },
    canceled: {
      title: 'canceled',
      message: `Your package is canceled and you will receive a refund for your purchase in accordance with the rules of the store where the purchase was made. If you have any problems, please contact us through the chat where the purchase was made and we will together understand what happened.`,
      type: 'error',
    },
    deliveryAttempt: {
      title: 'delivery attempt',
      message: `The logistic partner informed us that he tried to deliver but was unsuccessful. We'll try again the next business day! Get ready to receive the package 🙂`,
      type: 'alert',
    },
    waitingPickupReceiver: {
      title: 'waiting pickup',
      message: `Oops, your order is awaiting pickup! This can happen for two reasons: delivery failure or some temporary restriction with the logistics partner. Now, we need you to go to the address below along with the code and a document with a photo to receive your product.`,
      type: 'alert',
    },
    addressNotFound: {
      title: 'delivery not made',
      message: `The logistics partner tried to deliver your package but identified a discrepancy in the address registered for delivery.Get in touch with us through the chat where the purchase was made and we will together understand what happened.`,
      type: 'error',
    },
    reversePackage: {
      title: 'requested reverse',
      message:
        'We have received your return request, now we need you to follow the guidelines received and post the product at a post office using the code we have given you.',
      type: 'primary',
    },
    reverseDelivered: {
      title: 'returned to seller',
      message:
        'Your package was returned to our partner and now you need to send us a message in the chat where the purchase was made so that we can finalize your service.',
      type: 'primary',
    },
  };

  return descriptions[messageType] ?? '';
};
