import { useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { Caption, Modal } from '@olist/design-system';

import { Tooltip } from 'components/common/Tooltip';

import { Step } from './builder/Step';
import { KnowledgePill } from './components/KnowledgePill';
import {
  Bullet,
  StepLink,
  StepsList,
  StepsListItem,
  StepsListWrapperItems,
  Wrapper,
} from './Timeline.styles';

export interface ITimelineProps {
  steps: Step[];
  handleClickMovementButton?: () => void;
  handleCheckHistoryModal?: (visible: boolean) => void;
}

export const Timeline = ({
  handleCheckHistoryModal,
  handleClickMovementButton,
  steps,
}: ITimelineProps) => {
  const { t } = useTranslation();
  const [openModalInfoLink, setOpenModalInfoLink] = useState(false);

  // eslint-disable-next-line consistent-return
  const getHandleClickLink = (type: string, title: string) => {
    if (type === 'popover' && isMobile) {
      return () => setOpenModalInfoLink(!openModalInfoLink);
    }

    if (type === 'modal') {
      if (title === 'see movement' && handleClickMovementButton) return handleClickMovementButton;
      if (handleCheckHistoryModal) return () => handleCheckHistoryModal(true);
    }
  };

  const getLinkToStep = ({ link }: Pick<Step, 'link'>) => {
    const titleLink = t(link?.title || '');

    if (!link) return undefined;

    const isUrl = link?.type === 'link';

    if (link?.href && isUrl)
      return (
        <a target="_blank" href={link.href} rel="noreferrer">
          {titleLink}
        </a>
      );

    const handleClick = getHandleClickLink(link?.type, link.title);
    const descriptionMessage = link?.description?.message;
    const titleKnowledgePill = link?.description?.title || '';
    const typeKnowledgePill = link?.description?.type || 'info';
    const isStepWithTooltip = link?.description && descriptionMessage && !isMobile;

    return isStepWithTooltip ? (
      <Tooltip
        showCloseButton
        content={
          <KnowledgePill
            description={descriptionMessage}
            title={titleKnowledgePill}
            type={typeKnowledgePill}
          />
        }
      >
        <StepLink onClick={handleClick}>{titleLink}</StepLink>
      </Tooltip>
    ) : (
      <StepLink onClick={handleClick}>{titleLink}</StepLink>
    );
  };

  return (
    <Wrapper>
      <StepsList>
        {steps?.map(
          ({ complement, currentStatus, description, icon, isReverse, key, link, name }) => {
            const descriptionMessage = link?.description?.message;
            const titleKnowledgePill = link?.description?.title || '';
            const typeKnowledgePill = link?.description?.type || 'info';
            return (
              <StepsListItem key={key} title={name} status={currentStatus} isReverse={isReverse}>
                <StepsListWrapperItems status={currentStatus}>
                  <Bullet>{icon}</Bullet>
                  <Caption type="default">{t(name)}</Caption>
                  <Caption>{description}</Caption>
                  {getLinkToStep({ link })}
                  {complement}
                </StepsListWrapperItems>
                {isMobile && descriptionMessage && (
                  <Modal
                    isOpen={openModalInfoLink}
                    onClose={() => setOpenModalInfoLink(!openModalInfoLink)}
                    size="small"
                    title=""
                    body={
                      <KnowledgePill
                        description={descriptionMessage}
                        title={titleKnowledgePill}
                        type={typeKnowledgePill}
                      />
                    }
                  />
                )}
              </StepsListItem>
            );
          }
        )}
      </StepsList>
    </Wrapper>
  );
};
