import styled, { css } from 'styled-components';

import { Caption, ParagraphLg } from '@olist/design-system';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.olist.color.primary.softer};
  border-radius: ${({ theme }) => theme.olist.borderRadius.medium};
  display: grid;
  grid-template-columns: 1.4fr 1.6fr 1.6fr;
  justify-content: space-between;
  padding: ${({ theme }) => theme.olist.spacing.inset.medium};

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
    display: block;
  }
`;

export const ContentInfoDetails = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
    padding: ${({ theme }) => theme.olist.spacing.inset['2xsmall']};
  }
`;

type TitleInfoOrderProps = {
  isCanceled?: boolean;
  children: string | JSX.Element | JSX.Element[];
};

export const TitleInfoOrder = styled(ParagraphLg)<TitleInfoOrderProps>`
  font-weight: ${({ theme }) => theme.olist.fontWeight.bold};

  ${({ isCanceled, theme }) =>
    isCanceled &&
    css`
      color: ${theme.olist.color.alert.base};
    `}
`;

export const DetailInfoOrder = styled(Caption)`
  color: ${({ theme }) => theme.olist.color.neutral.strong};
`;

export const FreeFreightText = styled(Caption)`
  color: ${({ theme }) => theme.olist.color.accent.soft};
`;

export const WrapperResumeInfo = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;
