import styled, { css } from 'styled-components';

import { Caption } from '@olist/design-system';

import { StepStatus } from 'models/steps';

const Wrapper = styled.div`
  width: 100%;
`;

const StepsList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
    height: 100%;
    flex-direction: column;
  }
`;

type StepsListItemProps = {
  status: StepStatus;
  isReverse?: boolean;
};

const Bullet = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.olist.color.neutral.softer};
  border-radius: ${({ theme }) => theme.olist.borderRadius.circle};
  box-sizing: border-box;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

const StepsListItem = styled.li<StepsListItemProps>`
  align-items: center;
  flex-grow: 1;
  position: relative;

  ${({ isReverse, status, theme }) => {
    switch (status) {
      case StepStatus.done:
        return css`
          ${Bullet} {
            background-color: ${theme.olist.color.primary.softer};
            svg {
              color: ${theme.olist.color.primary.base};
            }
          }
        `;
      case StepStatus.delivered:
        return css`
          &:last-child ${Bullet} {
            background-color: ${theme.olist.color.accent.soft};
            svg {
              color: ${theme.olist.color.neutral.softest};
            }
          }

          ${Bullet} {
            background-color: ${theme.olist.color.accent.softer};

            svg {
              color: ${theme.olist.color.accent.soft};
            }
          }

          ${isReverse &&
          css`
            &:last-child ${Bullet} {
              background-color: ${theme.olist.color.primary.base};
              svg {
                color: ${theme.olist.color.neutral.softer};
              }
            }
            ${Bullet} {
              background-color: ${theme.olist.color.primary.softer};
              svg {
                color: ${theme.olist.color.primary.base};
              }
            }
          `}
        `;
      case StepStatus.active:
        return css`
          ${Bullet} {
            background-color: ${theme.olist.color.primary.base};
            svg {
              color: ${theme.olist.color.neutral.softer};
            }
          }
        `;
      case StepStatus.warning:
        return css`
          &:last-child ${Bullet} {
            background-color: ${theme.olist.color.notice.soft};
            svg {
              color: ${theme.olist.color.neutral.softest};
            }
          }

          ${Bullet} {
            background-color: ${theme.olist.color.notice.softer};
            svg {
              color: ${theme.olist.color.notice.soft};
            }
          }
        `;

      case StepStatus.error:
        return css`
          &:last-child ${Bullet} {
            background-color: ${theme.olist.color.alert.soft};
            svg {
              color: ${theme.olist.color.neutral.softest};
            }
          }
          ${Bullet} {
            background-color: ${theme.olist.color.alert.softer};
            svg {
              color: ${theme.olist.color.alert.soft};
            }
          }
        `;
      default:
        return css`
          svg {
                color: ${theme.olist.color.neutral.soft};
          }
          > ${Caption} {
            color: ${theme.olist.color.neutral.soft};
          }
          background-color: ${theme.olist.color.primary.stronger}
          border-radius: ${theme.olist.borderRadius.circle};
        `;
    }
  }}

  &:after {
    border-top: 4px solid;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;

    @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
      content: '';
      position: absolute;
      height: 100%;
      border: 0px;
      width: 6px;
      top: 0;
      bottom: 0;
      left: 0;
    }

    ${({ isReverse, status, theme }) => {
      switch (status) {
        case StepStatus.active:
          return css`
            background: ${theme.olist.color.primary.base};
            border-color: ${theme.olist.color.primary.base};
          `;
        case StepStatus.done:
          return css`
            background: ${theme.olist.color.primary.base};
            border-color: ${theme.olist.color.primary.base};
          `;
        case StepStatus.delivered:
          return css`
            background: ${theme.olist.color.accent.soft};
            border-color: ${theme.olist.color.accent.soft};

            ${isReverse &&
            css`
              background: ${theme.olist.color.primary.base};
              border-color: ${theme.olist.color.primary.base};
            `}
          `;
        case StepStatus.error:
          return css`
            background: ${theme.olist.color.alert.soft};
            border-color: ${theme.olist.color.alert.soft};
          `;
        case StepStatus.warning:
          return css`
            background: ${theme.olist.color.notice.soft};
            border-color: ${theme.olist.color.notice.soft};
          `;
        default:
          return css`
            background: ${theme.olist.color.neutral.softer};
            border-color: ${theme.olist.color.neutral.softer};
          `;
      }
    }}
  }
`;

const StepsListWrapperItems = styled.div<StepsListItemProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.olist.spacing.inset.small} 0px;

  > ${Caption} {
    margin-top: 5px;
    width: 120px;
    text-align: center;
  }

  a {
    font-size: ${({ theme }) => theme.olist.fontSize['1xmini']};
    margin-top: 5px;
  }

  > ${Caption}:nth-child(3) {
    color: ${({ theme }) => theme.olist.color.neutral.base};
    font-size: ${({ theme }) => theme.olist.fontSize['1xmini']};
  }
`;

const StepLink = styled(Caption)`
  color: ${({ theme }) => theme.olist.color.primary.base};
  cursor: pointer;
  font-size: ${({ theme }) => theme.olist.fontSize['1xmini']};
  margin-bottom: ${({ theme }) => theme.olist.spacing.inset.small};
`;

export { Wrapper, StepLink, StepsList, StepsListItem, StepsListWrapperItems, Bullet };
