import { useEffect } from 'react';

import MixPanel from 'mixpanel';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@olist/design-system';
import { Loading } from '@olist/united';

import { Container, FeedbackBlankState } from 'components/common';
import { OrderDetails } from 'components/order/OrderDetails';
import { WrapperLoading } from 'components/order/OrderDetails/OrderDetails.styles';
import useMixpanel from 'hooks/common/useMixpanel';
import { ISellerOrder } from 'models/order';
import { maskText } from 'utils/formatters';
import { mixPanelToken } from 'utils/mixpanel';

interface OrderProps {
  order: ISellerOrder;
  isError?: boolean;
}

export default function Order({ isError, order }: OrderProps) {
  const { t } = useTranslation();

  const { isFallback } = useRouter();
  const { track } = useMixpanel();

  useEffect(() => {
    if (!isFallback && order) {
      track('render order detail page', {
        order: order.id,
      });
    }
  }, [track, order, isFallback]);

  if (isError) {
    return <FeedbackBlankState title="erro" subtitle={t(`There was an error loading order.`)} />;
  }

  if (isFallback) {
    return (
      <Container>
        <Skeleton width="100%" height="100px" />
        <WrapperLoading>
          <Loading />
        </WrapperLoading>
      </Container>
    );
  }

  return <OrderDetails order={order} />;
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: true,
  };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  try {
    const mixpanel = MixPanel.init(mixPanelToken);

    const { order } = params as { order: string };

    const orderResponse = await fetch(`${process.env.TRACKING_BFF_HOST}/orders/search/${order}`);

    if (orderResponse.status === 404) {
      return {
        redirect: {
          destination: '/?notFound=true',
          permanent: false,
        },
      };
    }

    const formattedOrder = await orderResponse.json();

    const document = formattedOrder?.customer?.documentNumber;

    const sellerOrderIntentCode = formattedOrder?.sellerOrderIntentCode;

    const resendSellerOrder = sellerOrderIntentCode
      ? await fetch(`${process.env.TRACKING_BFF_HOST}/orders/search/${sellerOrderIntentCode}`)
      : null;

    const formattedResendSellerOrder = resendSellerOrder ? await resendSellerOrder.json() : null;

    mixpanel.track('see details order', {
      distinct_id: document,
      document,
      codeSearch: order,
    });

    const orderWithoutCustomerInfos = {
      ...formattedOrder,
      resendSellerOrder: formattedResendSellerOrder,
      customer: {
        name: maskText(formattedOrder?.customer.name),
        address: formattedOrder?.customer.address,
      },
    };
    return {
      props: {
        order: orderWithoutCustomerInfos,
      },
      revalidate: 60 * 60 * 3,
    };
  } catch (error) {
    return {
      props: {
        isError: true,
      },
    };
  }
};
