import { StepStatus } from 'models/steps';

type StatusPackage = {
  [key: string]: {
    name: string;
    status: StepStatus;
  };
};

const StatusPackage: StatusPackage = {
  created: {
    name: 'Posted object',
    status: StepStatus.done,
  },
  ready_to_ship: {
    name: 'Posted object',
    status: StepStatus.done,
  },
  shipped: {
    status: StepStatus.done,
    name: 'In transit',
  },
  delivered: {
    status: StepStatus.active,
    name: 'Complete Delivery',
  },
  awaiting_pickup_by_receiver: {
    status: StepStatus.warning,
    name: 'Waiting for package pickup',
  },
  partially_delivered: {
    status: StepStatus.warning,
    name: 'partially delivered',
  },
  returning_to_sender: {
    status: StepStatus.error,
    name: 'in return to sender',
  },
  returned_to_sender: {
    status: StepStatus.error,
    name: 'Order returned to sender',
  },
  collected: {
    status: StepStatus.done,
    name: 'Order en route for delivery to the Distribution Center',
  },
  partially_collected: {
    status: StepStatus.done,
    name: 'Order en route for delivery to the Distribution Center',
  },
  received: {
    status: StepStatus.done,
    name: 'Order received at Distribution Center',
  },
  stolen: {
    status: StepStatus.error,
    name: 'There was a problem with your order and it will be dealt with by our customer service.',
  },
  lost: {
    status: StepStatus.error,
    name: 'Lost order. Your request will be handled by our customer service.',
  },
  canceled: {
    status: StepStatus.error,
    name: 'canceled',
  },
  seized_for_inspection: {
    status: StepStatus.warning,
    name: 'Seized for inspection',
  },
};

export { StatusPackage };
