import styled from 'styled-components';

const Header = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.olist.spacing.stack.large};
  width: 100%;

  > div {
    align-items: center;
    display: flex;
    width: inherit;

    > div {
      padding-left: ${({ theme }) => theme.olist.spacing.inline.small};
    }
    > span {
      margin-left: ${({ theme }) => theme.olist.spacing.inline.small};

      svg {
        height: 0px;
        margin-right: 0px;
        width: 0px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
    flex-direction: column-reverse;
    align-items: flex-start;

    > div {
      justify-content: space-between;
    }

    > button {
      margin-bottom: ${({ theme }) => theme.olist.spacing.stack.small};
    }
  }

  > button {
    margin-top: ${({ theme }) => theme.olist.spacing.stack.medium};
  }
`;

export { Header };
