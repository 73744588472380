import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Caption, Modal } from '@olist/design-system';

import { Product } from 'components/order/Product';
import { ISellerOrder } from 'models/order';
import { ISellerOrderItem } from 'models/order/history';
import { IPackage } from 'models/order/package';
import { IVolume } from 'models/order/volume';

import { Container, ContainerItems } from './Resend.styles';
import { PackageHistory } from '../PackageHistory';
import { Timeline } from '../Timeline';
import useResendStepsTimeline from '../Timeline/hooks/useResendStepsTimeline';
import useStepsTimeline from '../Timeline/hooks/useStepsTimeline';

type ResendProps = {
  packages: IPackage[];
  sellerOrderItems: ISellerOrderItem[];
  resendSellerOrder?: ISellerOrder | null;
};

export const Resend = ({ packages, resendSellerOrder, sellerOrderItems }: ResendProps) => {
  const { stepsTimelineResend } = useResendStepsTimeline(packages);

  const resendSellerOrderIsDelivered = resendSellerOrder?.status === 'canceled';

  const resendSellerOrderVolume = resendSellerOrder?.volumes?.[0] || ({} as IVolume);
  const sellerOrderItemsResendSellerOrder = resendSellerOrder?.sellerOrderItems || [];

  const { stepsTimeline } = useStepsTimeline(
    resendSellerOrderVolume,
    resendSellerOrder?.sellerOrderHistory,
    resendSellerOrderIsDelivered
  );

  const [isOpenSeeMovement, setIsOpenSeeMovement] = useState(false);

  const { t } = useTranslation();

  const findPackageByShipmentType = (
    packageList: IPackage[] | undefined,
    type: string
  ): IPackage | undefined => packageList?.find(({ shipmentType }) => shipmentType === type);

  const resendPackage = resendSellerOrder
    ? findPackageByShipmentType(resendSellerOrderVolume?.packages, 'normal')
    : findPackageByShipmentType(packages, 'resend');

  const filterResendItems = (
    items: ISellerOrderItem[] | undefined,
    packageItems?: ISellerOrderItem & { sellerOrderItemId: string }[]
  ): ISellerOrderItem[] | undefined =>
    items?.filter(({ id }) => packageItems?.find((item) => item?.sellerOrderItemId === id));

  const resendItems =
    resendSellerOrder && sellerOrderItemsResendSellerOrder
      ? filterResendItems(sellerOrderItemsResendSellerOrder, resendPackage?.sellerOrderItems)
      : filterResendItems(sellerOrderItems, resendPackage?.sellerOrderItems);

  const handleClickButtonMovement = () => {
    setIsOpenSeeMovement(true);
  };

  return (
    <Container>
      <Timeline
        steps={resendSellerOrder ? stepsTimeline : stepsTimelineResend}
        handleClickMovementButton={handleClickButtonMovement}
      />

      <ContainerItems>
        <Caption>{t('package items')}</Caption>
        <div>
          {resendItems?.map((product) => (
            <Product key={product?.id || product?.productSku} product={product} />
          ))}
        </div>
      </ContainerItems>

      {resendPackage && (
        <Modal
          isOpen={isOpenSeeMovement}
          onClose={() => setIsOpenSeeMovement(!isOpenSeeMovement)}
          title={t('shipping movement')}
          size="small"
          body={
            <PackageHistory packageId={resendPackage?.id} packageVolumeId={resendPackage?.id} />
          }
        />
      )}
    </Container>
  );
};
