import { useCallback, useState } from 'react';

import { IHistory } from 'models/order/history';
import { StepStatus } from 'models/steps';
import { getHourAndMinutes } from 'utils/formatters';

import { StatusPackage } from '../helpers/constants';

type StepHistory = {
  name: string;
  description: string;
  status: StepStatus;
  trackingDate: string;
};

function useStepsHistory() {
  const [stepsHistory, setStepsHistory] = useState<StepHistory[]>([]);

  const getStepsHistory = useCallback((history: IHistory[]) => {
    const steps = history?.reduce((historySteps, { lastTrackingDate, status, trackingStatus }) => {
      const dateToDescription = new Date(lastTrackingDate);

      const hoursAndMinutes = getHourAndMinutes(dateToDescription);
      const postedObjectStep = !historySteps?.find((step) => step.name === 'Posted object');
      const postedStatus = status === 'created' || status === 'ready_to_ship';

      if ((postedObjectStep && postedStatus) || !postedStatus) {
        const step = {
          trackingDate: `${dateToDescription.toLocaleDateString('pt-BR')} ${hoursAndMinutes}`,
          description: trackingStatus,
          ...StatusPackage[status],
        };
        historySteps.push(step);
      }
      return historySteps;
    }, [] as StepHistory[]);

    setStepsHistory(steps || []);
  }, []);

  return { getStepsHistory, stepsHistory };
}

export default useStepsHistory;
