import { CloseOutline, HomeOutline } from '@olist/design-system-icons';

import { StepStatus } from 'models/steps';

import { DescriptionLink, InfoLink, InfoOrderAndVolumes } from './models';
import { getInfoLinkKnowlogeBills, getMessageType } from './utils';
import {
  errorStatus,
  invoicedStatus,
  keysStepsTimeLine,
  orderConfimedStatus,
  readyToShippedStatus,
  warningStatus,
} from './utils/constants';

type Link = {
  href?: string;
  key: keyof typeof keysStepsTimeLine;
  type: 'popover' | 'link' | 'modal';
  title: string;
  description?: DescriptionLink;
};

export class Step {
  key: keyof typeof keysStepsTimeLine;

  icon: JSX.Element;

  name: string;

  status: string[];

  description?: string;

  currentStatus: StepStatus;

  complement?: JSX.Element;

  link?: Link;

  isReverse?: boolean;

  constructor(
    key: keyof typeof keysStepsTimeLine,
    icon: JSX.Element,
    name: string,
    status: string[],
    description?: string,
    currentStatus?: StepStatus,
    link?: Link,
    isReverse = false
  ) {
    this.key = key;
    this.icon = icon;
    this.name = name;
    this.status = status;
    this.description = description;
    this.currentStatus = currentStatus || StepStatus.inactive;
    this.link = link;
    this.isReverse = isReverse;
  }

  setName(name: string): void {
    this.name = name;
  }

  setIcon(icon: JSX.Element): void {
    this.icon = icon;
  }

  setCurrentDescription(status: StepStatus | string, description?: string): void {
    if (status === StepStatus.inactive || !description) {
      this.description = undefined;
      return;
    }
    this.description = description;
  }

  setCurrentName(
    status: string,
    { isCanceled = false, isExchange = false, isResend }: InfoOrderAndVolumes
  ): void {
    const isDeliveredPackage = status === 'delivered';
    const isCanceledPackage = status === 'canceled' || isCanceled;
    const isReturnedPackage = status === 'returned_to_sender' || status === 'returning_to_sender';
    const isLostPackage = status === 'lost';
    const isDeliveryAttempt = status === 'delivery_attempt';
    const isWaitingPickupReceiver = status === 'waiting_pickup_by_receiver';
    const isReadyToShipPackage = readyToShippedStatus.includes(status);

    switch (this.key) {
      case 'awaitingDelivery':
        if (isDeliveredPackage) this.setName('delivered');
        if (isCanceledPackage) this.setName('canceled');
        if (isReturnedPackage) this.setName('went back to the store');
        if (isLostPackage) this.setName('Damaged, misplaced or lost by the carrier');
        if (isDeliveryAttempt) this.setName('delivery attempt');
        if (isWaitingPickupReceiver) {
          this.setName(
            'waiting for withdrawal by the customer at the agency for up to 7 consecutive days'
          );
        }

        break;

      case 'packageInTransit':
        if (isReadyToShipPackage) this.setName('ready to shipped');
        break;

      case 'orderConfirmed':
        if (isExchange) this.setName('order requested from an exchange');
        if (isResend) this.setName('order requested from a resend');

        break;

      default:
        break;
    }
  }

  setCurrentStatus(status: StepStatus): void {
    this.currentStatus = status;
  }

  setCurrentStatusStep(
    status: string,
    nextStepIsCompleted = false,
    isNewDeliveryDate = false
  ): StepStatus {
    const isErrorStatus = errorStatus.includes(status);
    const isWarningStatus = warningStatus.includes(status);
    const isActived = this.status.includes(status);
    const isDelivered = status === 'delivered';
    const isDoneStatus = status === 'done';

    if (isErrorStatus || isNewDeliveryDate) {
      this.setCurrentStatus(StepStatus.error);
    } else if (isWarningStatus) {
      this.setCurrentStatus(StepStatus.warning);
    } else if (isDelivered) {
      this.setCurrentStatus(StepStatus.delivered);
    } else if (isDoneStatus) {
      this.setCurrentStatus(StepStatus.done);
    } else if (isActived) {
      this.currentStatus = StepStatus.active;
    } else if (nextStepIsCompleted) {
      this.currentStatus = StepStatus.done;
    } else {
      this.currentStatus = StepStatus.inactive;
    }

    return this.currentStatus;
  }

  setCurrentLink(status: string, infosLink: InfoLink): void {
    const withSeeMovement = ![...invoicedStatus, ...orderConfimedStatus].includes(status);
    const href = infosLink?.href;
    const isResendPackage = infosLink?.isResend;
    const isReversePackage = infosLink?.isReverse;
    const isOrderCanceled = infosLink?.isCanceled;

    const messageType = getMessageType(status, isOrderCanceled, isReversePackage);

    const defaultLink = {
      key: this.key,
      type: 'popover' as const,
      title: 'check',
    };

    switch (this.key) {
      case 'invoiced':
        if (!href) return;
        this.link = {
          href,
          key: this.key,
          type: 'link',
          title: 'view invoice XML',
        };
        break;

      case 'packageInTransit':
        if (withSeeMovement && !isReversePackage)
          this.link = {
            key: this.key,
            type: 'modal',
            title: 'see movement',
          };
        break;

      case 'awaitingDelivery':
        if (isResendPackage) {
          this.link = {
            key: this.key,
            type: 'modal',
            title: 'check history',
          };
        } else if (messageType) {
          this.link = {
            ...defaultLink,
            description: getInfoLinkKnowlogeBills(messageType),
          };
        }

        break;

      case 'requestedReverse':
        this.link = {
          ...defaultLink,
          description: getInfoLinkKnowlogeBills('reversePackage'),
        };
        break;

      case 'reverseToSeller':
        this.link = {
          ...defaultLink,
          description: getInfoLinkKnowlogeBills('reverseDelivered'),
        };
        break;

      default:
        this.link = undefined;
    }
  }

  setCurrentIcon(status: string): void {
    const { key } = this;

    if (key === 'awaitingDelivery') {
      const isErrorStatus = errorStatus.includes(status);
      const isWarningStatus = warningStatus.includes(status);

      if (isErrorStatus) {
        this.setIcon(<CloseOutline />);
      } else if (isWarningStatus) {
        this.setIcon(<HomeOutline />);
      }
    }
  }

  setComplement(complement?: JSX.Element): void {
    if (!complement) return;
    this.complement = complement;
  }
}
