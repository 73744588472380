import styled, { css } from 'styled-components';

import { Caption } from '@olist/design-system';

import { StepStatus } from 'models/steps';

const Container = styled.div`
  padding: 0px ${({ theme }) => theme.olist.spacing.inline.small};
  width: auto;
`;

const WrapperSkeleton = styled.div`
  width: 100%;
`;

const WrapperInfoShipping = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.olist.spacing.inline.small};
  width: 100%;

  > span {
    > span {
      font-weight: bold;
    }
  }
`;

const HistoryList = styled.ul`
  list-style: none;
  margin: 0 auto;
  position: relative;
  width: 100%;
`;

type HistoryItemProps = {
  status: StepStatus;
};

const HistoryItemDescription = styled(Caption)`
  color: ${({ theme }) => theme.olist.color.neutral.base};
  font-size: ${({ theme }) => theme.olist.fontSize['1xmini']};
`;

const HistoryItem = styled.li<HistoryItemProps>`
  height: auto;
  position: relative;

  > div {
    display: inline-grid;
    padding: 0px ${({ theme }) => theme.olist.spacing.inline.small};

    &:before {
      background-color: ${({ theme }) => theme.olist.color.accent.soft};
      border-radius: ${({ theme }) => theme.olist.borderRadius.circle};
      content: '';
      left: -4px;
      top: 8px;
      height: 10px;
      width: 10px;
      position: absolute;
    }
  }

  &:last-child {
    > div {
      &:after {
        content: '';
        background-color: transparent;
        left: -8px;
        top: 4px;
        position: absolute;
        border: 1px solid;
        height: 16px;
        border-radius: ${({ theme }) => theme.olist.borderRadius.circle};
        width: 16px;

        ${({ status, theme }) => {
          switch (status) {
            case StepStatus.active:
              return css`
                border-color: ${theme.olist.color.primary.base};
              `;
            case StepStatus.done:
              return css`
                border-color: ${theme.olist.color.primary.base};
              `;
            case StepStatus.delivered:
              return css`
                border-color: ${theme.olist.color.accent.soft};
              `;
            case StepStatus.error:
              return css`
                border-color: ${theme.olist.color.alert.soft};
              `;
            case StepStatus.warning:
              return css`
                border-color: ${theme.olist.color.notice.soft};
              `;
            default:
              return css`
                border-color: ${theme.olist.color.primary.base};
              `;
          }
        }};
      }
      &:before {
        ${({ status, theme }) => {
          switch (status) {
            case StepStatus.active:
              return css`
                background-color: ${theme.olist.color.primary.base};
              `;
            case StepStatus.done:
              return css`
                background-color: ${theme.olist.color.primary.base};
              `;
            case StepStatus.delivered:
              return css`
                background-color: ${theme.olist.color.accent.soft};
              `;
            case StepStatus.error:
              return css`
                background-color: ${theme.olist.color.alert.soft};
              `;
            case StepStatus.warning:
              return css`
                background-color: ${theme.olist.color.notice.soft};
              `;
            default:
              return css`
                background-color: ${theme.olist.color.primary.base};
              `;
          }
        }};
      }
    }
  }

  &:not(:last-child):before {
    background-color: ${({ theme }) => theme.olist.color.neutral.soft};
    bottom: -5%;
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
  }
`;

const FirstHistoryItem = styled.div`
  height: 70px;
  position: relative;
`;

const WrapperArriveDate = styled.div`
  div:first-child {
    display: flex;
    padding-left: 12px;

    svg {
      position: absolute;
      left: -12px;
    }
    div {
      display: flex;
      flex-direction: column;
      padding: 0px ${({ theme }) => theme.olist.spacing.inline['2xsmall']};
      span:first-child {
        span {
          font-weight: ${({ theme }) => theme.olist.fontWeight.bold};
        }

        & + span {
          color: ${({ theme }) => theme.olist.color.neutral.base};
        }
      }
    }
  }
`;

const DashedLineFirtHistoryItem = styled.div`
  border-left: 1px dashed ${({ theme }) => theme.olist.color.neutral.soft};
  bottom: 0;
  flex-grow: 1;
  height: 60%;
  position: absolute;
`;

export {
  Container,
  WrapperArriveDate,
  HistoryList,
  HistoryItem,
  FirstHistoryItem,
  DashedLineFirtHistoryItem,
  WrapperSkeleton,
  HistoryItemDescription,
  WrapperInfoShipping,
};
