import styled, { css } from 'styled-components';

const Container = styled.div`
  width: 100%;
`;

const ContainerItems = styled.div`
  margin-top: ${({ theme }) => theme.olist.spacing.stack.medium};
  > span {
    font-weight: bold;
  }

  > div {
    ${({ theme }) => css`
      margin: ${theme.olist.spacing.stack.medium} ${theme.olist.spacing.stack.large};
    `};
  }
`;

export { Container, ContainerItems };
