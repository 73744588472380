import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Caption, Skeleton } from '@olist/design-system';
import { CalendarOutline } from '@olist/design-system-icons';

import { FeedbackBlankState } from 'components/common';
import usePackageData from 'hooks/package/usePackageData';
import { addInitialHourToDate } from 'utils/formatters';
import { months } from 'utils/helpers';

import useStepsHistory from './hooks/useStepsHistory';
import {
  Container,
  DashedLineFirtHistoryItem,
  FirstHistoryItem,
  HistoryItem,
  HistoryItemDescription,
  HistoryList,
  WrapperArriveDate,
  WrapperInfoShipping,
  WrapperSkeleton,
} from './PackageHistory.styles';

export type PackageHistoryProps = {
  packageId?: string;
  packageVolumeId?: string;
  deliveryDate?: Date;
};

export const PackageHistory = ({
  deliveryDate,
  packageId,
  packageVolumeId,
}: PackageHistoryProps) => {
  const { t } = useTranslation();

  const isVolumeId = !packageId;

  const id = packageId || packageVolumeId;

  const { isError, isLoading, packageData } = usePackageData(id, isVolumeId);

  const { getStepsHistory, stepsHistory } = useStepsHistory();

  const history = packageData?.history;

  useEffect(() => {
    if (history) {
      getStepsHistory(history);
    }
  }, [history, getStepsHistory]);

  const packageIsDelivered = packageData?.status === 'delivered';

  const historyDelivered = useMemo(
    () => history?.find(({ status }) => status === 'delivered'),
    [history]
  );

  if (isError) return <FeedbackBlankState title={t('error')} subtitle={t(`There was an error.`)} />;

  if (isLoading || !packageData) {
    return (
      <WrapperSkeleton>
        <Skeleton width="100%" height="100px" />
      </WrapperSkeleton>
    );
  }

  const realDeliveryDate =
    deliveryDate ||
    (packageIsDelivered && historyDelivered
      ? new Date(historyDelivered?.lastTrackingDate)
      : new Date(addInitialHourToDate(packageData?.estimatedDeliveryDate)));

  const deliveredDate = {
    deliveredDay: realDeliveryDate.getDate(),
    deliveredMonth: t(months[realDeliveryDate?.getMonth()]),
  };

  const trackingCode = packageData?.trackingCode;
  const slug = packageData?.carrierName;

  return (
    <>
      <WrapperInfoShipping>
        <Caption>
          {t('tracking code')}: <Caption>{trackingCode}</Caption>
        </Caption>
        <Caption>
          {t('carrier')}: <Caption>{slug}</Caption>
        </Caption>
      </WrapperInfoShipping>
      <Container>
        <HistoryList>
          <FirstHistoryItem>
            <WrapperArriveDate>
              <div>
                <CalendarOutline />
                <div>
                  <Caption>
                    {packageIsDelivered ? t('arrived on the') : t('will arrive on the')}{' '}
                    <Caption>{t('{{deliveredDay}} of {{deliveredMonth}}', deliveredDate)}</Caption>
                  </Caption>
                  <Caption>
                    {packageData.address}, {packageData.number} {packageData.district},
                    {packageData.city}-{packageData.state}
                  </Caption>
                </div>
              </div>
            </WrapperArriveDate>
            <DashedLineFirtHistoryItem />
          </FirstHistoryItem>
          {stepsHistory?.map(({ description, name, status, trackingDate }) => (
            <HistoryItem key={description} status={status}>
              <div>
                <Caption>{t(name)}</Caption>
                <HistoryItemDescription>{trackingDate}</HistoryItemDescription>
                {description && <HistoryItemDescription>{description}</HistoryItemDescription>}
              </div>
            </HistoryItem>
          ))}
        </HistoryList>
      </Container>
    </>
  );
};
