import styled, { css } from 'styled-components';

const Container = styled.section`
  padding: ${({ theme }) => theme.olist.spacing.stack.medium} 0px
    ${({ theme }) => theme.olist.spacing.stack.medium};
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.olist.spacing.stack.large};
  width: 100%;

  > div {
    align-items: center;
    display: flex;
    width: inherit;

    > div {
      padding-left: ${({ theme }) => theme.olist.spacing.inline.small};
    }
    > span {
      margin-left: ${({ theme }) => theme.olist.spacing.inline.small};

      svg {
        height: 0px;
        margin-right: 0px;
        width: 0px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
    > div {
      justify-content: space-between;
    }

    > button {
      display: none;
    }
  }
`;

const WrapperItems = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.olist.color.neutral.soft};
    border-radius: ${theme.olist.borderRadius.medium};
    padding: ${theme.olist.spacing.inset.medium};
  `}
`;
export { Container, WrapperItems, Header };
