export const invoicedStatus = ['invoiced', 'payment_approved'];

export const readyToShippedStatus = ['created', 'ready_to_ship', 'received'];

export const packageInTransitStatus = [
  ...readyToShippedStatus,
  'collected',
  'shipped',
  'seized_for_inspection',
];

export const errorStatus = [
  'returned_to_sender',
  'returning_to_sender',
  'canceled',
  'lost',
  'stolen',
  'error',
];

export const warningStatus = ['delivery_attempt', 'waiting_pickup_by_receiver', 'warning'];

export const keysStepsTimeLine = {
  orderConfirmed: 'orderConfirmed',
  invoiced: 'invoiced',
  packageInTransit: 'packageInTransit',
  awaitingDelivery: 'awaitingDelivery',
  requestedReverse: 'requestedReverse',
  reverseToSeller: 'reverseToSeller',
};

export const orderConfimedStatus = [
  'pending',
  'created',
  'processing_volumes',
  'carrier_pending',
  'carrier_assigned',
  'generated',
  'approved',
];

export const returnToSenderStatus = ['returned_to_sender', 'returning_to_sender'];
