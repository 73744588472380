import { useTranslation } from 'react-i18next';

import { Badge, SectionSubheading } from '@olist/design-system';

import { Product } from 'components/order/Product';
import { OrderItem } from 'models/order/item';

import { Container, Header, WrapperItems } from './OrderItems.styles';

type OrderItemsProps = {
  orderItems: OrderItem[];
};

export function OrderItems({ orderItems }: OrderItemsProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <div>
          <SectionSubheading>{t('items')}</SectionSubheading>
          <Badge variation="primary">{t('{{count}} items', { count: orderItems.length })}</Badge>
        </div>
      </Header>

      <WrapperItems>
        {orderItems.map((product) => (
          <Product product={product} key={product.id} />
        ))}
      </WrapperItems>
    </Container>
  );
}
