import { useState } from 'react';

import { CloseOutline } from '@olist/design-system-icons';

import useOutsideClick from 'hooks/common/useOutsideClick';

import {
  TooltipChildren,
  TooltipContainer,
  TooltipContent,
  TooltipContentHeader,
} from './Tooltip.styles';

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  contentHeader?: string | React.ReactNode;
  isHoverShow?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  onClick?: () => void;
}

export function Tooltip({
  children,
  content,
  contentHeader,
  isHoverShow,
  onClick,
  onClose,
  showCloseButton = false,
}: TooltipProps) {
  const [isShow, setIsShow] = useState(false);

  const handleShowTooltip = (visible: boolean) => {
    if (onClose && !visible) onClose();
    if (onClick && visible) onClick();

    setIsShow(visible);
  };

  const ref = useOutsideClick(() => handleShowTooltip(false));

  return (
    <TooltipContainer ref={ref} show={isShow} isHoverShow={isHoverShow}>
      <TooltipChildren onClick={() => handleShowTooltip(true)}>{children}</TooltipChildren>
      <TooltipContent>
        {contentHeader ||
          (showCloseButton && (
            <TooltipContentHeader>
              <div>{contentHeader}</div>
              <CloseOutline onClick={() => handleShowTooltip(false)} width={20} />
            </TooltipContentHeader>
          ))}

        {content}
      </TooltipContent>
    </TooltipContainer>
  );
}
