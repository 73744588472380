import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Caption } from '@olist/design-system';

import { ICustomer } from 'models/order/customer';
import { IVolume } from 'models/order/volume';
import { formatZipCode } from 'utils/formatters';
import { months } from 'utils/helpers';

import {
  Container,
  ContentInfoDetails,
  DetailInfoOrder,
  FreeFreightText,
  TitleInfoOrder,
  WrapperResumeInfo,
} from './Resume.styles';

export type ResumeProps = {
  code: string;
  totalItems: number;
  totalAmount: number;
  channelStore: string;
  orderDate: Date;
  customer: ICustomer;
  totalFreight: string;
  estimatedDeliveryDate?: Date;
  status: string;
  countItems: number;
  deliveredCustomerDate: string;
  isCanceled: boolean;
  reverseIsReadyToShipped?: boolean;
  volumes: IVolume[];
};

export const Resume = ({
  channelStore,
  code,
  countItems,
  customer,
  deliveredCustomerDate,
  estimatedDeliveryDate,
  isCanceled,
  orderDate,
  reverseIsReadyToShipped = false,
  status,
  totalAmount,
  totalFreight,
  totalItems,
  volumes,
}: ResumeProps) => {
  const { t } = useTranslation();

  const orderDateFormat = orderDate.toLocaleTimeString(['pt-BR'], {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  const resendInProgress = volumes?.some(({ packages }) =>
    packages?.some(
      ({ shipmentType, status: statusPackages }) =>
        (shipmentType === 'resend' && statusPackages === 'shipped') ||
        statusPackages === 'ready_to_ship'
    )
  );

  const deliveredAddress = customer?.address;

  const freeFreight = totalFreight === '0.00';

  const isDelivered = status === 'delivered';

  const estimatedDelivery = useMemo(() => {
    if (estimatedDeliveryDate)
      return {
        day: estimatedDeliveryDate?.getDate(),
        month: t(months[estimatedDeliveryDate?.getMonth()]),
      };

    return undefined;
  }, [estimatedDeliveryDate, t]);

  const deliveredCustomer = useMemo(() => new Date(deliveredCustomerDate), [deliveredCustomerDate]);

  const deliveredDate = useMemo(() => {
    if (isDelivered)
      return {
        day: deliveredCustomer.getDate(),
        month: t(months[deliveredCustomer.getMonth()]),
      };

    return undefined;
  }, [isDelivered, deliveredCustomer, t]);

  const titleInfoOrder = useMemo(() => {
    if (reverseIsReadyToShipped && isCanceled) return t('reverse order');
    if (isCanceled) return t('order canceled');
    if (resendInProgress) return t('resend in progress');
    if (isDelivered && deliveredDate) return t('arrived on {{day}} of {{month}}', deliveredDate);
    if (estimatedDelivery) return t('will arrive by {{day}} of {{month}}', estimatedDelivery);

    return '';
  }, [
    isCanceled,
    isDelivered,
    deliveredDate,
    estimatedDelivery,
    resendInProgress,
    reverseIsReadyToShipped,
    t,
  ]);

  const totalProducts = (Number(totalAmount) - Number(totalFreight)).toFixed(2);

  return (
    <Container>
      <ContentInfoDetails>
        <TitleInfoOrder>{customer?.name}</TitleInfoOrder>
        <DetailInfoOrder>
          {t('channel')} {channelStore}
        </DetailInfoOrder>
        <DetailInfoOrder>#{code}</DetailInfoOrder>
        <DetailInfoOrder color="neutral.softest">{orderDateFormat}</DetailInfoOrder>
      </ContentInfoDetails>
      <ContentInfoDetails>
        <TitleInfoOrder isCanceled={isCanceled}>{titleInfoOrder}</TitleInfoOrder>
        <DetailInfoOrder>
          {deliveredAddress?.address}, {deliveredAddress?.number}
        </DetailInfoOrder>
        <DetailInfoOrder>
          {deliveredAddress?.district}, {deliveredAddress?.city}-{deliveredAddress?.state}
        </DetailInfoOrder>
        <DetailInfoOrder>CEP {formatZipCode(deliveredAddress?.zipCode)}</DetailInfoOrder>
      </ContentInfoDetails>

      <ContentInfoDetails>
        <TitleInfoOrder>{t('resume') || ''}</TitleInfoOrder>
        <WrapperResumeInfo>
          <DetailInfoOrder>
            {countItems > 1 ? t('value of products') : t('value of product')}
          </DetailInfoOrder>
          <Caption>R$ {isCanceled ? totalProducts : totalItems}</Caption>
        </WrapperResumeInfo>
        <WrapperResumeInfo>
          <DetailInfoOrder>{t('freight')}</DetailInfoOrder>
          {freeFreight ? (
            <FreeFreightText color="green">{t('free')}</FreeFreightText>
          ) : (
            <DetailInfoOrder>R$ {totalFreight}</DetailInfoOrder>
          )}
        </WrapperResumeInfo>

        <WrapperResumeInfo>
          <TitleInfoOrder>{t('total order') || ''}</TitleInfoOrder>
          <TitleInfoOrder>{`R$ ${totalAmount}`}</TitleInfoOrder>
        </WrapperResumeInfo>
      </ContentInfoDetails>
    </Container>
  );
};
