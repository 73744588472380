import { useState } from 'react';

import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Badge, Button, Caption, Flex, Modal } from '@olist/design-system';
import { AlertTriangleOutline } from '@olist/design-system-icons';

import { Product } from 'components/order/Product';
import { ISellerOrder } from 'models/order';
import { ISellerOrderHistory } from 'models/order/history';
import { IVolume } from 'models/order/volume';
import { getNameAndVariationStatusOrder } from 'utils/order';
import { shipmentTypesReverse } from 'utils/order/constants';

import {
  AlertNewDeliveryDate,
  Container,
  ContainerItems,
  TitlePackage,
  WrapperTitle,
  WrappersSection,
} from './Package.styles';
import { PackageHistory } from './PackageHistory';
import { Resend } from './Resend';
import { Timeline } from './Timeline';
import useStepsTimeline from './Timeline/hooks/useStepsTimeline';

export type PackageProps = {
  packageInfo: {
    numberPackage: number;
    totalPackages: number;
  };
  packageVolume: IVolume;
  deliveryDate?: Date;
  sellerOrderHistory: ISellerOrderHistory[];
  resendSellerOrder?: ISellerOrder | null;
  isOrderCanceled: boolean;
  invoiceUrl?: string;
};
export const Package = ({
  deliveryDate,
  invoiceUrl,
  isOrderCanceled,
  packageInfo,
  packageVolume,
  resendSellerOrder,
  sellerOrderHistory,
}: PackageProps) => {
  const { t } = useTranslation();
  const { push } = useRouter();

  const [isOpenSeeMovement, setIsOpenSeeMovement] = useState(false);
  const [isVisibibleCheckHistoryModal, setVisibleCheckHistoryModal] = useState(false);

  const { id, isResendVolume, packages, sellerOrderItems, status } = packageVolume;

  const reversePackage = packages?.filter((packageItem) =>
    shipmentTypesReverse.includes(packageItem.shipmentType)
  );

  const reversaIsReadyToShip = reversePackage?.some(({ status: statusReverse }) =>
    ['ready_to_ship', 'shipped'].includes(statusReverse)
  );

  const resendPackage = packages?.find(({ shipmentType }) => shipmentType === 'resend');

  const packageStatus = isOrderCanceled ? 'canceled' : status;

  const { stepsTimeline } = useStepsTimeline(
    packageVolume,
    sellerOrderHistory,
    isOrderCanceled,
    deliveryDate,
    invoiceUrl,
    resendSellerOrder
  );

  const packageInfoStatus = getNameAndVariationStatusOrder(packageStatus);

  const handleClickButtonMovement = () => {
    setIsOpenSeeMovement(true);
  };

  const handleCheckHistoryModal = (visible: boolean) => {
    setVisibleCheckHistoryModal(visible);
  };

  const handleCloseModalResend = () => {
    setVisibleCheckHistoryModal(!isVisibibleCheckHistoryModal);
  };

  const packageIsDelivered = status === 'delivered';
  const packageIsLost = status === 'lost';
  const packageReturned = status === 'returned_to_sender' || status === 'returning_to_sender';
  const showAlertNewDeliveryDate = deliveryDate && !packageIsDelivered && !packageIsLost;
  const showAlertPackageDeadline =
    !deliveryDate && !packageIsDelivered && !packageIsLost && !packageReturned;

  const hideBadgeInfo =
    (reversaIsReadyToShip || resendPackage) &&
    (isOrderCanceled || packageStatus === 'canceled' || packageIsLost);

  const titlePackage = t('package {{numberPackage}} of {{totalPackages}}', packageInfo);

  const isOnlyResend =
    (resendPackage && !reversePackage.length && packages.length > 1) || Boolean(resendSellerOrder);

  return (
    <Container>
      {!isOrderCanceled && showAlertNewDeliveryDate && (
        <AlertNewDeliveryDate>
          <AlertTriangleOutline />
          <Caption>
            {t('Attention, a new delivery forecast has been updated for this package.')}
          </Caption>
        </AlertNewDeliveryDate>
      )}

      <WrapperTitle>
        <TitlePackage>
          <Caption>{titlePackage}</Caption>
          {!hideBadgeInfo && (
            <Badge variation={packageInfoStatus.variation}>{t(packageInfoStatus.name)}</Badge>
          )}
        </TitlePackage>

        {hideBadgeInfo && showAlertPackageDeadline && (
          <Badge variation="info">{t('package without delivery deadline')}</Badge>
        )}
      </WrapperTitle>

      <WrappersSection>
        <Timeline
          handleClickMovementButton={handleClickButtonMovement}
          handleCheckHistoryModal={handleCheckHistoryModal}
          steps={stepsTimeline}
        />
      </WrappersSection>

      <ContainerItems>
        <Caption>{t('package items')}</Caption>
        <div>
          {sellerOrderItems.map((product) => (
            <Product key={product?.id || product?.productSku} product={product} />
          ))}
        </div>
      </ContainerItems>

      <Modal
        isOpen={isOpenSeeMovement}
        onClose={() => setIsOpenSeeMovement(!isOpenSeeMovement)}
        title={t('shipping movement')}
        size="small"
        body={
          <PackageHistory
            packageVolumeId={id}
            packageId={isResendVolume && resendPackage ? resendPackage.id : undefined}
            deliveryDate={deliveryDate}
          />
        }
      />

      <Modal
        isOpen={isVisibibleCheckHistoryModal}
        onClose={handleCloseModalResend}
        title={titlePackage}
        description={
          (isOnlyResend ? t('order requested from a resend') : t('exchange request')) || ''
        }
        size="large"
        body={
          <Resend
            sellerOrderItems={sellerOrderItems}
            packages={packageVolume?.packages}
            resendSellerOrder={resendSellerOrder}
          />
        }
        footer={
          resendSellerOrder && (
            <Flex justifyContent="space-between" width="100%">
              <Button variation="secondary" onClick={handleCloseModalResend}>
                {t('close')}
              </Button>
              <Button
                onClick={() =>
                  push({
                    pathname: '/orders/details/[code]',
                    query: { code: resendSellerOrder?.code },
                  })
                }
              >
                {t('check full order')}
              </Button>
            </Flex>
          )
        }
      />
    </Container>
  );
};
