import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Badge, Button, SectionHeading } from '@olist/design-system';
import { BadgeVariation } from '@olist/design-system/dist/esm/types/src/components/Badge/Badge';
import { ArrowLeftOutline } from '@olist/design-system-icons';

import { Header } from './HeaderOrderDetails.styles';

type HeaderOrderDetailsProps = {
  name: string;
  variantionBadge: BadgeVariation;
  lateNewDeliveryDate?: boolean;
  hideBadge?: boolean;
};

export function HeaderOrderDetails({
  hideBadge = false,
  lateNewDeliveryDate,
  name,
  variantionBadge,
}: HeaderOrderDetailsProps) {
  const { back } = useRouter();

  const onClickBackButton = () => {
    back();
  };
  const { t } = useTranslation();

  return (
    <Header>
      <div>
        <SectionHeading>{t('order details')}</SectionHeading>
        {!hideBadge && <Badge variation={variantionBadge}>{t(name)}</Badge>}
        {lateNewDeliveryDate && <Badge variation="error">{t('late delivery')}</Badge>}
      </div>
      <Button iconLeft={ArrowLeftOutline} variation="secondary" onClick={onClickBackButton}>
        {t('back')}
      </Button>
    </Header>
  );
}
