const maskText = (textToMask = '') => {
  const { length: len } = textToMask;
  return `${textToMask.slice(0, len / 4)} ... ... ${textToMask.slice(-len / 4)}`;
};

const formatZipCode = (zipCode: string) => {
  return zipCode
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};
const addInitialHourToDate = (dateToInitial = '') => new Date(`${dateToInitial}T00:00`);

const getHourAndMinutes = (date: Date) => {
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

  return `${hours}:${minutes}`;
};

const getFormattedDateBR = (date: Date): string => {
  const hoursAndMinutes = getHourAndMinutes(date);
  const formattedDate = `${date.toLocaleDateString('pt-BR')} ${hoursAndMinutes}`;
  return formattedDate;
};
export { maskText, addInitialHourToDate, getHourAndMinutes, formatZipCode, getFormattedDateBR };
