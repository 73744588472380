import { BadgeVariation } from '@olist/design-system/dist/esm/types/src/components/Badge/Badge';

const getNameAndVariationStatusOrder = (
  status: string
): {
  name: string;
  variation: BadgeVariation;
} => {
  switch (status) {
    case 'invoiced':
    case 'payment_approved':
      return {
        name: 'invoiced',
        variation: 'info',
      };

    case 'shipped':
      return {
        name: 'package in transit',
        variation: 'alert',
      };
    case undefined:
    case 'lost':
    case 'returned_to_sender':
    case 'returning_to_sender':
    case 'canceled':
      return {
        name: 'canceled',
        variation: 'error',
      };

    case 'delivered':
      return {
        name: 'delivered',
        variation: 'success',
      };

    case 'pending':
    case 'processing_volumes':
    default:
      return {
        name: 'processing',
        variation: 'primary',
      };
  }
};

export { getNameAndVariationStatusOrder };
