import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { IOrderNewDeliveryDate } from 'models/order';
import { request } from 'utils/request';

const useNewDeliveryDate = (channelOrderId?: string) => {
  const getNewDeliveryDate = async (channelOrder?: string): Promise<IOrderNewDeliveryDate> => {
    const response = await request.get(`delivery/${channelOrder}`);

    return response.data;
  };

  const { data, isError, isLoading, isSuccess, refetch } = useQuery<
    IOrderNewDeliveryDate,
    AxiosError
  >(['newDeliveryDate', channelOrderId], () => getNewDeliveryDate(channelOrderId), {
    enabled: Boolean(channelOrderId),
    refetchInterval: false,
    retry: false,
    cacheTime: 60 * 60 * 3,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  return {
    newDeliveryDate: data,
    isLoading,
    fetchNewDeliveryDate: refetch,
    isSuccess,
    isError,
  };
};

export default useNewDeliveryDate;
