import styled, { css } from 'styled-components';

const TooltipContent = styled.div`
  background-color: ${({ theme }) => theme.olist.color.neutral.softest};
  border: 1px solid ${({ theme }) => theme.olist.color.neutral.soft};
  border-radius: ${({ theme }) => theme.olist.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.olist.shadow.low};
  left: 50%;
  max-width: 20rem;
  opacity: 0;
  padding: ${({ theme }) => theme.olist.spacing.inset.small};
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  width: max-content;
  z-index: 100;

  &:before,
  &:after {
    border-bottom-style: solid;
    border-bottom-width: 0.65rem;
    border-left: 0.65rem solid transparent;
    border-right: 0.65rem solid transparent;
    content: '';
    height: 0;
    left: 50%;
    margin-left: -0.65rem;
    position: absolute;
    width: 0;
  }

  &:before {
    border-bottom-color: ${({ theme }) => theme.olist.color.neutral.soft};
    top: -0.7rem;
  }

  &:after {
    border-bottom-color: ${({ theme }) => theme.olist.color.neutral.softest};
    top: -0.6rem;
  }
`;

const TooltipContentHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  > svg {
    color: ${({ theme }) => theme.olist.color.neutral.stronger};
    cursor: pointer;
    z-index: 101;
  }
`;

const TooltipChildren = styled.div``;

const TooltipContainer = styled.div<{
  isHoverShow?: boolean;
  show?: boolean;
}>`
  flex-direction: column-reverse;
  position: relative;
  vertical-align: middle;

  ${({ isHoverShow }) =>
    isHoverShow &&
    css`
      &:hover ${TooltipContent} {
        opacity: 1;
        visibility: visible;
      }
    `}

  ${({ show }) =>
    show &&
    css`
      ${TooltipContent} {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

export { TooltipChildren, TooltipContent, TooltipContentHeader, TooltipContainer };
