import { useTranslation } from 'react-i18next';

import { Badge } from '@olist/design-system';

import { months } from 'utils/helpers';

import { Container, NewForecastTitle } from './NewDelivery.styles';

type NewDeliveryDateProps = {
  newDate: Date;
};

export const NewDeliveryDate = ({ newDate }: NewDeliveryDateProps) => {
  const { t } = useTranslation();

  const deliveredDate = {
    deliveredDay: newDate?.getDate(),
    deliveredMonth: t(months[newDate?.getMonth()]),
  };

  return (
    <Container>
      <Badge variation="alert">
        {t('until {{deliveredDay}} of {{deliveredMonth}}', deliveredDate)}
      </Badge>
      <NewForecastTitle>{t('new forecast')}</NewForecastTitle>
    </Container>
  );
};
