import { useTranslation } from 'react-i18next';

import { Badge, SectionSubheading } from '@olist/design-system';

import { ISellerOrder } from 'models/order';
import { ISellerOrderHistory } from 'models/order/history';
import { IVolume } from 'models/order/volume';

import { Package } from './Package';
import { Container, Header } from './Packages.styles';

type PackagesProps = {
  countItems: number;
  isCanceled: boolean;
  deliveryDate?: Date;
  sellerOrderHistory: ISellerOrderHistory[];
  resendSellerOrder?: ISellerOrder | null;
  volumes?: IVolume[];
  invoiceUrl?: string;
};

export function Packages({
  countItems,
  deliveryDate,
  invoiceUrl,
  isCanceled,
  resendSellerOrder,
  sellerOrderHistory,
  volumes,
}: PackagesProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <div>
          <SectionSubheading>{t('shipments')}</SectionSubheading>
          <Badge variation="primary">{t('{{count}} packages', { count: countItems })}</Badge>
        </div>
      </Header>

      {volumes?.map((packageVolume, index) => (
        <Package
          isOrderCanceled={isCanceled}
          key={packageVolume.id}
          invoiceUrl={invoiceUrl}
          deliveryDate={deliveryDate}
          packageInfo={{ numberPackage: index + 1, totalPackages: countItems }}
          packageVolume={packageVolume}
          resendSellerOrder={resendSellerOrder}
          sellerOrderHistory={sellerOrderHistory}
        />
      ))}
    </Container>
  );
}
