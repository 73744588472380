import { ISellerOrder } from 'models/order';
import { ISellerOrderHistory } from 'models/order/history';

import { Builder, InfosPackage } from './models';

export default class Director {
  private builder: Builder | undefined;

  public setBuilder(builder: Builder): void {
    this.builder = builder;
  }

  public buildTimeline(): void {
    this.builder?.setStepsOrderHistory();
    this.builder?.setStepsVolumeStatus();
  }

  public buildTimelineResend(infos: InfosPackage): void {
    this.builder?.setInfosPackage(infos);
    this.builder?.buildStepsResend();
  }

  public buildCurrentStatus(infos: InfosPackage, orderHistory: ISellerOrderHistory[]): void {
    this.builder?.setInfosPackage(infos);
    this.builder?.buildCurrentStatus(orderHistory);
  }

  public buildCurrentStatusWithReverse(
    infos: InfosPackage,
    orderHistory: ISellerOrderHistory[],
    isOnlyReverse = false
  ): void {
    this.builder?.setInfosPackage(infos);
    if (!isOnlyReverse) {
      this.builder?.buildCurrentStatus(orderHistory);
    }
    this.builder?.buildCurrentStatusReverse();
  }

  public buildResendSellerOrder(resendSellerOrder: ISellerOrder | null): void {
    this.builder?.setResendSellerOrder(resendSellerOrder);
  }
}
