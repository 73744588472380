import { useCallback, useEffect, useState } from 'react';

import { IPackage } from 'models/order/package';

import { Director, TimelineBuilder } from '../builder';
import { Step } from '../builder/Step';

function useResendStepsTimeline(packages: IPackage[]) {
  const [stepsTimelineResend, setStepsTimelineResend] = useState<Step[]>([]);

  const getDirectorAndBuilder = useCallback(() => {
    const director = new Director();
    const builder = new TimelineBuilder();
    director.setBuilder(builder);

    return { director, builder };
  }, []);

  useEffect(() => {
    const resendPackages = packages?.filter(({ shipmentType }) => shipmentType === 'resend');

    const buildTimelineResend = !stepsTimelineResend.length;

    if (buildTimelineResend) {
      const { builder, director } = getDirectorAndBuilder();

      director.setBuilder(builder);
      const statusPackage = resendPackages?.[0]?.status;

      const infosPackage = {
        packages,
        volumeStatus: statusPackage,
      };
      director.buildTimelineResend(infosPackage);
      const stepsResend = builder.getResendSteps();

      setStepsTimelineResend(stepsResend);
    }
  }, [getDirectorAndBuilder, packages, stepsTimelineResend.length]);

  return { stepsTimelineResend };
}

export default useResendStepsTimeline;
