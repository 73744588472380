import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ theme }) => theme.olist.spacing.inset['1xlarge']};

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.medium}) {
    padding-bottom: 20vh;
    width: auto;
  }
`;

const WrapperLoading = styled.div`
  display: flex;
  flex: 1;
  margin-top: 40vh;
`;

export { Container, WrapperLoading };
