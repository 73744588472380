import mixpanel from 'mixpanel-browser';

import { mixPanelToken } from 'utils/mixpanel';

mixpanel.init(mixPanelToken);

const useMixPanel = () => {
  const identify = (id: string) => {
    mixpanel?.identify(id);
  };

  const people = {
    set: (props: any) => {
      mixpanel?.people.set(props);
    },
  };

  const register = (props: any) => {
    mixpanel?.register(props);
  };

  const track = (name: string, props?: any) => {
    mixpanel?.track(name, props);
  };

  return {
    identify,
    people,
    register,
    track,
  };
};

export default useMixPanel;
