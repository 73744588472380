import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.olist.color.neutral.soft};
    border-radius: ${theme.olist.borderRadius.medium};
    margin-bottom: ${theme.olist.spacing.stack.medium};
    padding: ${theme.olist.spacing.inset.medium};
  `}
`;

const TitlePackage = styled.div`
  display: flex;
  > span {
    margin-left: ${({ theme }) => theme.olist.spacing.inline.small};

    svg {
      height: 0px;
      margin-right: 0px;
      width: 0px;
    }
  }
`;

const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > span {
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.small}) {
    display: flex;
    flex-direction: column;

    ${TitlePackage} {
      justify-content: space-between;
      margin-bottom: ${({ theme }) => theme.olist.spacing.stack['1xsmall']};
    }
  }
`;

const WrappersSection = styled.div`
  margin-top: ${({ theme }) => theme.olist.spacing.stack.large};
`;

const AlertNewDeliveryDate = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.olist.color.notice.softer};
  display: flex;

  margin-bottom: ${({ theme }) => theme.olist.spacing.stack.medium};

  padding: ${({ theme }) => theme.olist.spacing.inset['1xsmall']};

  svg {
    color: ${({ theme }) => theme.olist.color.notice.stronger};
  }

  span {
    color: ${({ theme }) => theme.olist.color.notice.stronger};
    padding-left: 1%;
  }
`;

const ContainerItems = styled(WrappersSection)`
  > span {
    font-weight: bold;
  }

  > div {
    ${({ theme }) => css`
      margin: ${theme.olist.spacing.stack.medium} ${theme.olist.spacing.stack.large};
    `};
  }
`;

export {
  Container,
  ContainerItems,
  AlertNewDeliveryDate,
  WrappersSection,
  WrapperTitle,
  TitlePackage,
};
