import { ISellerOrder } from 'models/order';
import { ISellerOrderHistory } from 'models/order/history';

import { Builder, InfosPackage } from './models';
import { Step } from './Step';
import { Timeline } from './Timeline';

export class TimelineBuilder implements Builder {
  private timeline!: Timeline;

  constructor() {
    this.reset();
  }

  public reset(): void {
    this.timeline = new Timeline();
  }

  public setStepsOrderHistory(): void {
    this.timeline.setStepsOrderHistory();
  }

  public setStepsVolumeStatus(): void {
    this.timeline.setStepsVolumeStatus();
  }

  public setInfosPackage(infos: InfosPackage): void {
    this.timeline.setInfosPackage(infos);
  }

  public setResendSellerOrder(resendSellerOrder: ISellerOrder | null): void {
    this.timeline.setResendSellerOrder(resendSellerOrder);
  }

  public buildCurrentStatus(orderHistory: ISellerOrderHistory[]): void {
    this.timeline.setCurrentStatusStepsFromVolume();
    this.timeline.setCurrentStatusStepsOrderHistory(orderHistory);
  }

  public buildCurrentStatusReverse(): void {
    this.timeline.setStepsReverse();
    this.timeline.setCurrentStatusStepsReverse();
  }

  public buildStepsResend(): void {
    this.timeline.setStepsResend();
    this.timeline.setCurrentStatusStepsResend();
  }

  public getTimeline(): Timeline {
    const resultTimeline = this.timeline;
    this.reset();
    return resultTimeline;
  }

  public getStepsTimeline(): Step[] {
    return this.timeline.getAllSteps();
  }

  public getResendSteps(): Step[] {
    return this.timeline.getStepsResend();
  }
}
