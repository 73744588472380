import { Caption } from '@olist/design-system';

import { ISellerOrderItem } from 'models/order/history';

import { Container, Image, WrapperInfoProduct, WrapperProduct } from './Product.styles';

export interface IProductProps {
  product: Partial<ISellerOrderItem>;
}

export const Product = ({ product }: IProductProps) => {
  const { fullName, price, productPhoto, quantity } = product;

  return (
    <Container>
      {productPhoto && (
        <Image quality={50} alt="product-image" src={productPhoto} width={55} height={55} />
      )}

      <WrapperProduct>
        <Caption>{fullName}</Caption>

        <WrapperInfoProduct>
          <Caption>{quantity} un </Caption>
          <Caption>R$ {price}</Caption>
        </WrapperInfoProduct>
      </WrapperProduct>
    </Container>
  );
};
