import { cnpj, cpf } from 'cpf-cnpj-validator';

export const months: string[] = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const isCpnjOrCpf = (value: string) => {
  const isCpnj = cnpj.isValid(value);
  const ordersFiltered = cpf.isValid(value);

  return ordersFiltered || isCpnj;
};

export const allowOnlyNumbers = (value: string) => {
  return value.replace(/[^0-9]/g, '');
};
