import {
  CreditCardOutline,
  PrintOutline,
  ReplaceOutline,
  SucessOutline,
  TruckOutline,
} from '@olist/design-system-icons';

import {
  invoicedStatus,
  keysStepsTimeLine,
  orderConfimedStatus,
  packageInTransitStatus,
} from './constants';

export const stepsOrderHistoryDefault = [
  {
    key: 'orderConfirmed' as keyof typeof keysStepsTimeLine,
    icon: <CreditCardOutline />,
    name: 'order confirmed',
    status: orderConfimedStatus,
  },
  {
    key: 'invoiced' as keyof typeof keysStepsTimeLine,
    icon: <PrintOutline />,
    name: 'invoiced',
    status: invoicedStatus,
  },
];

export const stepsVolumeStatusDefault = [
  {
    key: 'packageInTransit' as keyof typeof keysStepsTimeLine,
    icon: <TruckOutline />,
    name: 'package in transit',
    status: packageInTransitStatus,
  },
  {
    key: 'awaitingDelivery' as keyof typeof keysStepsTimeLine,
    icon: <SucessOutline />,
    name: 'awaiting delivery',
    status: ['delivered'],
  },
];

export const stepsReveseDefault = [
  {
    key: 'requestedReverse' as keyof typeof keysStepsTimeLine,
    icon: <ReplaceOutline />,
    name: 'requested reverse',
    status: ['ready_to_ship'],
  },
  {
    key: 'packageInTransit' as keyof typeof keysStepsTimeLine,
    icon: <TruckOutline />,
    name: 'package in transit',
    status: ['shipped', 'returning_to_sender'],
  },
  {
    key: 'reverseToSeller' as keyof typeof keysStepsTimeLine,
    icon: <SucessOutline />,
    name: 'returned to seller',
    status: ['delivered', 'returned_to_sender'],
  },
];

export const stepsResendDefault = [
  {
    key: 'orderConfirmed' as keyof typeof keysStepsTimeLine,
    icon: <CreditCardOutline />,
    name: 'order requested from an exchange',
    status: ['ready_to_ship'],
  },
  {
    key: 'packageInTransit' as keyof typeof keysStepsTimeLine,
    icon: <TruckOutline />,
    name: 'package in transit',
    status: ['shipped'],
  },
  {
    key: 'awaitingDelivery' as keyof typeof keysStepsTimeLine,
    icon: <SucessOutline />,
    name: 'delivered',
    status: ['delivered'],
  },
];
