import styled from 'styled-components';

const KnowledgePillContainer = styled.div`
  display: block;
  > span {
    margin-bottom: ${({ theme }) => theme.olist.spacing.stack['1xsmall']};
    width: fit-content;
  }
`;

const KnowledgePillContent = styled.div`
  align-items: flex-start;
  display: flex;
  min-width: 220px;

  > svg {
    color: ${({ theme }) => theme.olist.color.neutral.stronger};
    margin-right: 2%;
  }

  > span {
    color: ${({ theme }) => theme.olist.color.neutral.strong};
    width: 98%;
  }
`;

export { KnowledgePillContainer, KnowledgePillContent };
