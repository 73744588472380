import styled from 'styled-components';

import { Caption } from '@olist/design-system';

export const Container = styled.div`
  display: contents;
  span {
    > svg {
      height: 0px;
      width: 0px;
    }
  }
`;

export const NewForecastTitle = styled(Caption)`
  color: ${({ theme }) => theme.olist.color.alert.stronger};
  font-size: ${({ theme }) => theme.olist.fontSize['1xmini']};
  font-weight: ${({ theme }) => theme.olist.fontWeight.bold};
`;
