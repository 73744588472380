export enum StepStatus {
  done = 'done',
  active = 'active',
  warning = 'warning',
  inactive = 'inactive',
  error = 'error',
  delivered = 'delivered',
}

export interface IStep {
  key?: string;
  name: string;
  icon: JSX.Element;
  description?: string;
  link?: {
    href?: string;
    name: string;
  };
  status: StepStatus;
  component?: JSX.Element;
}
